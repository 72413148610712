import MGrid from "@mumble/components/grid/Grid.vue";
import MGridColumn from "@mumble/components/grid-column/GridColumn.vue";
import MInput from "@mumble/components/input/Input.vue";
import MButton from "@mumble/components/button/Button.vue";
import MAlert from "@mumble/components/alert/Alert.vue";

const components = { MGrid, MGridColumn, MInput, MButton, MAlert }

export default defineNuxtPlugin((nuxtApp) => {
    Object.entries(components).forEach(([name, component]) => {
        nuxtApp.vueApp.component(name, component)
    })
})

